import React from 'react';
import PropTypes from 'prop-types';
import { dateService } from '../../../domain/services/dateService';
import { ContentHtml } from '../ContentHtml';
import { ParagraphWrapper } from '../ParagraphWrapper';

const PromoParagraphInner = ({ data }) => {
  const isWithinDateRange = dateService.isWithinDateRange(
    Math.floor(new Date().getTime() / 1000),
    data?.entity?.mainContent?.entity?.startDate?.timestamp,
    data?.entity?.mainContent?.entity?.endDate?.timestamp
  );

  const mustShowComponent = data?.entity?.isPublished && isWithinDateRange;

  return mustShowComponent ? (
    <ContentHtml data={data.entity.mainContent.entity} />
  ) : (
    <div />
  );
};

PromoParagraphInner.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired
};

export const PromoParagraph = ParagraphWrapper(PromoParagraphInner);
